body {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.login {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 40%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginButton {
  width: 150px;
  padding: 15px 25px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
}

.google {
  background-color: #df4930;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.loginTitle {
  color: darkgray;
  margin-bottom: 20px;
}

.main-container {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  height: 600px;
}

.chat-container {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

.sidebar {
  max-width: 50px !important;
}
